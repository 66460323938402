import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { G2gAvatarComponent } from '../../form/g2g-avatar/g2g-avatar.component';
import { G2gBtnFollowComponent } from '../g2g-btn-follow/g2g-btn-follow.component';
import { Router } from '@angular/router';
import { FollowService } from '../../../services/follow.service';
import { PrimeModule } from '../../../modules/primeng.module';

@Component({
  selector: 'app-g2g-user-tile',
  standalone: true,
  imports: [
    G2gBtnFollowComponent,
    G2gAvatarComponent,
    PrimeModule
  ],
  templateUrl: './g2g-user-tile.component.html',
  styleUrl: './g2g-user-tile.component.scss'
})
export class G2gUserTileComponent implements OnInit {
  @Input('user') user: any;
  @Input('compact') compact: boolean = false;
  @Output('onTileClick') userClick: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    private followService: FollowService
  ) { }

  ngOnInit(): void {
    this.followService.followStatus$.subscribe(status => {
      this.user.isFollowed = status.get(this.user.id) ?? this.user.isFollowed;
    });
  }

  visitProfile(userId: string) {
    if (userId) {
      this.userClick.emit(true);
      this.router.navigateByUrl(`user-profile/${userId}`);
    }
  }

}
