<p-toast />
@if(isMobile) {
<!-- MOBILE DETECTED -->
<div class="banner-container">

    <img src="/images/g2g-banner.png" height="300px">
    <div>
        <h2 class="font-bold">Prefer it portable!</h2>
        <h6>On the go? Get the iOS or Android App to be discovered and to find best talents across the world.
        </h6>
    </div>
    <div class="app-action-container">
        <table>
            <tr>
                <td rowspan="2">
                    <img src="/images/apple-icon.png" height="35">
                </td>
                <td><small>Download on the</small></td>
            </tr>
            <tr>
                <td>
                    <h5>App Store</h5>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td rowspan="2">
                    <img src="/images/play-store-icon.png" height="35">
                </td>
                <td><small>GET IT ON</small></td>
            </tr>
            <tr>
                <td>
                    <h5>Google Play</h5>
                </td>
            </tr>
        </table>
    </div>
</div>
} @else {
@if (showLoader && httpMonitor.getPendingRequests() > 0) {
<div class="loading-mask"></div>
}
@if (isLoggedIn && !isOnRestrictedPage) {
<app-header></app-header>
<div class="d-flex">
    @if(isSubscribed) {
        <app-sidebar></app-sidebar>
    }
    <div class="w-100 route-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>
} @else {
<router-outlet></router-outlet>
}
}
<app-toaster></app-toaster>