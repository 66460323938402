import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiconstants } from '../constants/api.constant';
import { IResponse } from '../models/response';
import { IVideoTile } from '../models/video-tile';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  constructor(
    private _http: HttpClient,
  ) { }

  getAllPosts(params: any): Observable<any> {
    return this._http.get(apiconstants.API_POSTS, { params }).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  getPostByPostId(id: string): Observable<any> {
    let url = apiconstants.API_GET_POSTS_BY_ID.replace('{id}', id);
    return this._http.get(url).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  likePost(videoId: string): Observable<any> {

    let url = apiconstants.API_LIKE_POST.replace('{id}', videoId);
    return this._http.post(url, null).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  unlikePost(videoId: string): Observable<any> {

    let url = apiconstants.API_UNLIKE_POST.replace('{id}', videoId);
    return this._http.post(url, null).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  getPostsByUserId(userId: string): Observable<any> {
    let url = apiconstants.API_GET_USER_POSTS.replace('{id}', userId);
    return this._http.get(url).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  searchVideoByName(name: string): Observable<any> {
    let params = {
      name
    }
    return this._http.get(apiconstants.API_SEARCH_VIDEO, { params }).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  getTrendingPosts(): Observable<IVideoTile[]> {
    return this._http.get(apiconstants.API_TRENDING_POSTS).pipe(
      map((response: IResponse) => {
        return <IVideoTile[]>response.data;
      })
    );
  }

  getUserLikesByPostId(postId: string): Observable<any> {
    let url = apiconstants.API_GET_POST_LIKE_INFO.replace('{id}', postId);
    return this._http.get(url).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

}
