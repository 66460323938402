import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../../modules/shared/shared.module';
import { PrimeModule } from '../../../modules/primeng.module';

@Component({
  selector: 'app-video-list-tile',
  standalone: true,
  imports: [SharedModule, PrimeModule],
  templateUrl: './video-list-tile.component.html',
  styleUrl: './video-list-tile.component.scss'
})
export class VideoListTileComponent {
  @Input('post') post: any;
  
  constructor(private router: Router) { }

  gotoVideo(videoId: any) {
    this.router.navigateByUrl(`/watch/${videoId}`);
  }

  gotoUserProfile(userId: any) {
    this.router.navigateByUrl(`user-profile/${userId}`);
  }
}
