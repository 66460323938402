import { FormsModule } from '@angular/forms';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { debounce } from 'lodash';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { UserService } from '../../../services/user.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { FollowService } from '../../../services/follow.service';

@Component({
  selector: 'app-g2g-btn-follow',
  standalone: true,
  imports: [FormsModule, ToggleButtonModule],
  templateUrl: './g2g-btn-follow.component.html',
  styleUrl: './g2g-btn-follow.component.scss'
})
export class G2gBtnFollowComponent implements OnDestroy, OnInit {
  @Input('playerId') playerId!: string | undefined;
  @Input('isFollowed') isFollowed: boolean = false;
  private $unsubscribe = new Subject<void>();
  private followStatusSubscription!: Subscription;

  constructor(
    private _userService: UserService,
    private _authService: AuthService,
    private followService: FollowService
  ) { }

  ngOnInit(): void {
    this.followStatusSubscription = this.followService.followStatus$.subscribe(status => {
      this.isFollowed = status.get(this.playerId || '') ?? this.isFollowed;
    });
  }

  interact() {
    this.followService.updateFollowStatus(this.playerId || '', this.isFollowed);
    this.performAction();
  }

  performAction = debounce(() => {
    if (this.playerId) {
      this.toggleFollow(this.playerId, this.isFollowed);
    }
  }, 500);

  toggleFollow(userId: string, isBeingFollowed: boolean) {
    this._authService.showLoader.next(false);
    this._userService.toggleFollowUser(userId, isBeingFollowed)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((res) => {
        this._authService.showLoader.next(true);
      }, (err) => {
        this._authService.showLoader.next(true);
      });
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
    this.followStatusSubscription.unsubscribe();
  }
}
