import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { G2gUserTileComponent } from '../g2g-user-tile/g2g-user-tile.component';
import { PageEvent } from '../../../models/page-event';
import { PaginatorModule } from 'primeng/paginator';
import { UserService } from '../../../services/user.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-g2g-user-list',
  standalone: true,
  imports: [
    G2gUserTileComponent,
    PaginatorModule
  ],
  templateUrl: './g2g-user-list.component.html',
  styleUrl: './g2g-user-list.component.scss'
})
export class G2gUserListComponent implements OnInit, OnDestroy {
  public users: any;
  public first: number = 0;
  public page: number = 1;
  public rows: number = 3;
  public totalCount: number = 0;
  public filter: string = '';

  private $unsubscribe = new Subject<void>();

  constructor(
    private _userService: UserService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers(filter?: string, showLoader?: boolean) {
    if(showLoader == false) {
      this._authService.showLoader.next(false);
    } 
    let params = {};
    if (filter) {
      params = { page: this.page, rows: this.rows, filter };
    } else {
      params = { page: this.page, rows: this.rows };
    }
    this._userService.getAllUsers(params)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((users: any[]) => {
        this.users = users || [];
      });

    let options = {};
    if (filter) {
      options = { filter };
    } else {
      options = {};
    }
    this._userService.getAllUsers(options)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((users: any[]) => {
        this.totalCount = users.length;
      });
  }

  onPageChange(event: PageEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + 1;
    this.getAllUsers(this.filter);
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
