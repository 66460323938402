import { Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { SubscriptionGuard } from './helpers/subscription.guard';

export const routes: Routes = [{
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent)
}, {
    path: 'select-user',
    loadComponent: () => import('./pages/choose-persona/choose-persona.component').then((m) => m.ChoosePersonaComponent)
}, {
    path: 'register',
    loadComponent: () => import('./pages/register/register.component').then((m) => m.RegisterComponent)
}, {
    path: 'forgot-password',
    loadComponent: () => import('./pages/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent)
}, {
    canActivate: [AuthGuard],
    path: 'subscribe',
    loadComponent: () => import('./pages/subscription/subscription.component').then((m) => m.SubscriptionComponent)
}, {
    canActivate: [AuthGuard],
    path: 'checkout/success',
    loadComponent: () => import('./pages/checkout/success/success.component').then((m) => m.SuccessComponent)
}, {
    canActivate: [AuthGuard],
    path: 'checkout/cancelled',
    loadComponent: () => import('./pages/checkout/cancelled/cancelled.component').then((m) => m.CancelledComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'home',
    loadComponent: () => import('./pages/home/home.component').then((m) => m.HomeComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'chat',
    loadComponent: () => import('./pages/chat/chat.component').then((m) => m.ChatComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'discover',
    loadComponent: () => import('./pages/discover/discover.component').then((m) => m.DiscoverComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'settings',
    loadComponent: () => import('./pages/settings/settings.component').then((m) => m.SettingsComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'profile',
    loadComponent: () => import('./pages/profile/profile.component').then((m) => m.ProfileComponent),
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'trials',
    loadComponent: () => import('./pages/trials/trials.component').then((m) => m.TrialsComponent),
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'dashboard',
    loadComponent: () => import('./pages/dashboard/dashboard.component').then((m) => m.DashboardComponent),
}, {
    canActivate: [AuthGuard],
    path: 'setup',
    loadComponent: () => import('./pages/setup/setup.component').then((m) => m.SetupComponent)
}, {
    canActivate: [AuthGuard],
    path: 'switch-user',
    loadComponent: () => import('./pages/switch-user/switch-user.component').then((m) => m.SwitchUserComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'search',
    loadComponent: () => import('./pages/search/search.component').then((m) => m.SearchComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'watch/:id',
    loadComponent: () => import('./pages/watch/watch.component').then((m) => m.WatchComponent)
}, {
    canActivate: [AuthGuard, SubscriptionGuard],
    path: 'user-profile/:id',
    loadComponent: () => import('./pages/player-profile/player-profile.component').then((m) => m.PlayerProfileComponent)
}, {
    path: '**',
    redirectTo: 'login'
}];
