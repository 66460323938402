import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PrimeModule } from '../../../modules/primeng.module';
import { ToastService } from '../../../services/toast-service';

@Component({
  selector: 'app-g2g-avatar',
  standalone: true,
  imports: [PrimeModule],
  templateUrl: './g2g-avatar.component.html',
  styleUrl: './g2g-avatar.component.scss'
})
export class G2gAvatarComponent {

  constructor(
    private _toastService: ToastService
  ) { }

  public files: any = [];
  @Input('initials') initials: string = '';
  @Input('interactive') interactive: boolean = true;
  @Input('size') size: 'normal' | 'large' | 'xlarge' = 'normal';
  @Input('image') selectedImage: any = null;
  @Input('styleClass') styleClass: any = null;
  @Output('getSelectedImage') getSelectedImage: EventEmitter<any> = new EventEmitter<any>();
  @Output('onAvatarClick') onAvatarClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('file', { static: false }) file!: ElementRef<HTMLElement>;

  onSelectFile(event: any) {

    if (event.target.files && event.target.files[0]) {

      const file = event.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes

      if (file.size > maxSize) {
        this._toastService.show({ message: "Image size exceeds the maximum limit of 5MB.", panelClass: 'bg-danger text-light', delay: 2000 });
        console.log(this.selectedImage);
        (this.selectedImage)?this.selectedImage:null;
      } else {
        const reader = new FileReader();
        this.returnSelectedImage(event.target.files[0]);
        reader.onload = (event: any) => { // called once readAsDataURL is completed
          this.selectedImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]); // read file as data url
      }
    }
  }

  returnSelectedImage(image: any) {
    this.getSelectedImage.emit(image);
  }

  triggerClick(): void {
    if (!this.interactive) {
      this.onAvatarClick.emit();
    } else {
      const el: HTMLElement = this.file.nativeElement;
      el.click();
    }
  }

}