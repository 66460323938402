<li class="player-card" [class.scout-card]="user?.type?.id == 2" (click)="$event.preventDefault(); $event.stopPropagation(); visitProfile(user?.id)">
    @defer {
        <div class="player-info">
            <app-g2g-avatar [interactive]="false" size="large" [image]="user?.profilePicture || user?.profilePictureURL"></app-g2g-avatar>
            <div class="player-details">
                <h2 class="player-name single-line-clamp">{{ user?.name }}</h2>
                <p class="player-meta line-clamp">
                    @if(user?.type?.id == 1) {
                    <span>
                        {{user?.position?.name}}
                    </span>
                    } @else if(user?.userType?.id == 1) {
                    <span class="text-gold">
                        Player
                    </span>
                    } @else {
                    <span class="text-gold">
                        Scout
                    </span>
                    }
                    @if(user?.age) {
                    <span>
                        {{ '• ' + user?.age }}
                    </span>
                    }
                    @if(user?.country) {
                    <span>
                        {{'• ' + user?.country}}
                    </span>
                    }
                </p>
            </div>
        </div>
    } @placeholder (minimum 500ms) {
        <table border="0" class="w-100 mt-2">
            <tr>
                <td width="80px">
                    <p-skeleton shape="circle" size="4rem" />
                </td>
                <td class="text-left">
                    <p-skeleton styleClass="mb-2" />
                    <p-skeleton width="10rem" />
                </td>
            </tr>
        </table>
    }

    @if(!compact) {
    <div class="follow-btn d-flex align-items-center mx-2">
        <app-g2g-btn-follow [isFollowed]="user?.isFollowed" [playerId]="user?.id"></app-g2g-btn-follow>
    </div>
    }
</li>