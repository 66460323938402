import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FollowService {
    private followStatusSubject = new BehaviorSubject<Map<string, boolean>>(new Map());
    followStatus$ = this.followStatusSubject.asObservable();

    constructor() { }

    updateFollowStatus(userId: string, isFollowed: boolean) {
        const currentStatus = this.followStatusSubject.value;
        currentStatus.set(userId, isFollowed);
        this.followStatusSubject.next(currentStatus);
    }

}
