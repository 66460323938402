import { takeUntil, finalize } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate, OnDestroy {

    $unsubscribe = new Subject<void>();
    constructor(private router: Router, private _authService: AuthService, private _storageService: StorageService) { }

    async canActivate() {
        let status = false;
        status = await new Promise((resolve, reject) => {
            this._authService.isSubscribed.subscribe((result) => {
                if(!result) {
                    this.router.navigate(['/subscribe']);
                    return resolve(false);
                } else {
                    return resolve(true);
                }
            });
        });
        return status;
    }

    ngOnDestroy() {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    }
}
